<template>
  <v-dialog v-if="isOpen" v-model="isOpen" width="1200" min-height="800" max-height="800">
    <v-card>
      <v-card-title>
        <v-menu offset-y offset="16" :disabled="!messages">
          <template #activator="{ props }">
            <div v-bind="props" class="d-flex flex-row flex-grow-1 align-center">
              {{
                editTargetGroups
                  ? 'Edit message target grouppings'
                  : editedMessage
                    ? 'Edit message condition'
                    : 'Edit insight rule / condition'
              }}

              <v-spacer />

              <v-btn v-if="messages" rounded size="small" icon="mdi-triangle-down" class="mt-n1" variant="tonal" />
            </div>
          </template>

          <v-sheet class="pa-4" style="max-height: 300px; overflow: auto">
            <v-list>
              <v-list-item v-if="rules" title="Edit insight rule / condition" @click="open(insight.id)">
                <template #append>
                  <span class="v-list-item-subtitle">{{ insight.id }}</span>
                </template>
              </v-list-item>

              <v-list-item
                v-for="(message, index) in messages"
                :key="message.id"
                :title="'Edit conditions for message ' + (index + 1)"
                @click="open(message.id)"
              >
                <template #append>
                  <span class="v-list-item-subtitle">{{ message.id }}</span>
                </template>
              </v-list-item>
              <v-list-item
                :key="'TargetGroups'"
                :title="'Edit message target grouppings'"
                @click="open('targetGroups')"
              >
                <template #append>
                  <span class="v-list-item-subtitle">Grouppings</span>
                </template>
              </v-list-item>
            </v-list>
          </v-sheet>
        </v-menu>
      </v-card-title>

      <v-card-subtitle class="mt-n4 mb-4">{{ editedMessage?.id || editedInsight?.id }}</v-card-subtitle>

      <v-card-text class="d-flex flex-column flex-shrink-1">
        <v-alert type="info" class="mb-6 flex-grow-0 flex-shrink-0">
          To release the changes saved in this dialog you need to rollout the
          <b>"Condition changes"</b>
          project!
        </v-alert>

        <div v-if="editedInsight" class="d-flex flex-row flex-grow-1 flex-shrink-1" style="min-height: 0">
          <div
            class="pr-8 flex-grow-1 flex-shrink-1"
            style="overflow: auto; min-height: 0; min-width: 400px; max-width: 400px"
          >
            <template v-if="editRule">
              <template v-for="(rule, index) in editedInsight.rules" :key="rule">
                <div :class="`rounded-lg d-flex pa-4 pb-2 bg-${findColor(rule)}-lighten-3`">
                  <p>{{ findRule(rule)?.title }}</p>

                  <v-spacer />

                  <v-icon
                    icon="mdi-trash-can-outline"
                    style="cursor: pointer; font-size: 18px"
                    class="align-self-center ml-2 mt-n2"
                    @click="removeRule(rule)"
                  />
                </div>

                <p
                  v-if="index < editedInsight.rules.length - 1 || editedInsight.rule"
                  :key="`and${rule}`"
                  class="my-2 text-center"
                >
                  AND
                </p>
              </template>

              <v-chip
                v-if="editedInsight.rule"
                label
                text="Insight specific rule"
                class="mb-2"
                style="min-width: 100%"
              />
            </template>

            <template v-else>
              <v-text-field v-model="ruleFilter" autofocus persistent-placeholder placeholder="Filter rules..." />

              <div
                v-for="rule in rules.filter(
                  (r) =>
                    !editedInsight?.rules.includes(r.id) &&
                    (!ruleFilter || r.title.toLowerCase().includes(ruleFilter.toLowerCase())),
                )"
                :key="rule.id"
                style="cursor: pointer"
                :class="'rounded-lg mb-2 d-flex pa-2 bg-' + findColor(rule.id) + '-lighten-3'"
                @click="appendRule(rule)"
              >
                <p class="text-truncate">{{ rule.title }}</p>
              </div>
            </template>
          </div>

          <div cols="12" md="8" style="border-left: 1px solid lightgrey" class="pl-8 flex-grow-1">
            <StatementPreview
              readonly
              label="Condition preview"
              :rows="14"
              :value="getCondition(false)"
              :highlight="sortedList.map((r) => r.condition)"
              :highlight-text="getCondition(true)"
              :highlight-color="sortedList.map((r) => findColor(r.id) + '-lighten-5')"
              :highlight-tooltip="sortedList.map((r) => r.title)"
            />

            <Statement v-model="editedInsight.rule" class="mt-4" simplify label="Insight specific rule" :rows="3" />
          </div>
        </div>

        <MessageCondition
          v-else-if="editedMessage"
          :message="editedMessage"
          :messages="messages"
          :target-groups="insightTargetGroups"
        />
        <MessageGroupping
          v-else-if="editTargetGroups"
          :insight="insight"
          :messages="editedMessages"
          :selected-message-path="messagePath + '/' + selectedMessageId"
          :message-snapshots="messageSnapshots"
          :target-groups="insightTargetGroups"
        />
      </v-card-text>

      <v-card-actions>
        <div v-if="!editedMessage" class="col-4">
          <v-btn v-if="editRule" color="primary" class="" text="Add existing rule" @click="editRule = false" />

          <v-btn v-else color="primary" text="Cancel rule adding" @click="editRule = true" />
        </div>

        <v-spacer />

        <v-btn text="Close" class="mr-4" @click="isOpen = false" />

        <v-btn v-if="!editTargetGroups" text="Save" color="primary" :disabled="!hasChanges" @click="save()" />
        <v-btn
          v-if="editTargetGroups"
          text="Save targets"
          color="primary"
          :disabled="!targetGroupsHasChanges"
          @click="save()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { cloneDeep, isEqual, orderBy } from 'lodash-es'

  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { insightFormulaCategories } from '#views/formulas/constants'

  import { FormulasStore, InsightsStore } from '#stores'

  import { Formula, Insight, Message } from '#types'

  @Component({})
  class EditRuleDialog extends Vue {
    @Prop() public rules!: Formula[]
    @Prop() public insight!: Insight
    @Prop() public messages!: Message[]
    @Prop() public messagePath!: string
    @Prop() public messageSnapshots!: string[]

    public isOpen = false
    public editTargetGroups = false
    public editRule = true

    public ruleFilter = ''

    public sortedList: Formula[] = []

    public editedInsight: Insight | null = null
    public editedMessage: Message | null = null

    public originalInsight: Insight | null = null
    public originalMessage: Message | null = null

    public originalMessages: Message[] | null = null
    public editedMessages: Message[] | null = null

    public insightTargetGroups: any[] = []

    public insightPath = ''
    public changedMessageIds: string[] = []
    public selectedMessageId: string = ''

    private readonly formulasStore = new FormulasStore()
    private readonly insightsStore = new InsightsStore()

    public get hasChanges() {
      return this.editedMessage
        ? !isEqual(this.originalMessage, this.editedMessage)
        : !isEqual(this.originalInsight, this.editedInsight)
    }

    public get targetGroupsHasChanges() {
      if (!this.originalMessages || !this.editedMessages) {
        return false
      }
      return !isEqual(this.originalMessages, this.editedMessages)
    }

    @Watch('rules', { immediate: true })
    protected onRulesChanged() {
      if (this.rules && this.rules.length) {
        this.sortedList = orderBy(this.rules, [(o) => o.condition.length], 'desc')
      }
    }

    public open(insightMessageId?: string, data?: any) {
      this.editRule = true
      this.fetchTargetGroups()
      if (insightMessageId === 'targetGroups') {
        this.editTargetGroups = true

        this.selectedMessageId = data?.selectedMessageId
        this.originalMessages = this.messages
        this.editedMessages = cloneDeep(this.messages)

        this.editedInsight = null
        this.originalInsight = this.insight
        this.editedMessage = null
        this.originalMessage = null
      } else {
        this.editTargetGroups = false
        this.originalMessages = null
        this.editedMessages = null
        const message = this.messages?.find((m) => m.id === insightMessageId) || null
        if (message) {
          this.editedInsight = null
          this.originalInsight = null
          this.editedMessage = cloneDeep(message)
          this.originalMessage = message
        } else {
          this.editedInsight = cloneDeep(this.insight)
          this.originalInsight = this.insight
          this.editedMessage = null
          this.originalMessage = null
        }
      }

      this.isOpen = true
    }

    public async save() {
      if (this.editedInsight) {
        await this.insightsStore.updateInsightCondition({
          id: this.editedInsight.id,
          rule: this.editedInsight.rule,
          rules: this.editedInsight.rules,
        })
      }

      if (this.editedMessage) {
        await this.insightsStore.updateMessageCondition(this.insight, {
          id: this.editedMessage.id,
          additionalCondition: this.editedMessage.additionalCondition,
          conditionSource: this.editedMessage.conditionSource,
          targetGroupCondition: this.editedMessage.targetGroupCondition,
        })
      }

      if (this.editTargetGroups) {
        await this.updateTargetGroups()
      }

      this.isOpen = false
    }

    public findRule(id: string) {
      return this.rules?.find((r) => r.id === id)
    }

    public findColor(id: string) {
      return insightFormulaCategories.find((c: any) => c.value === this.rules?.find((r) => r.id === id)?.category)
        ?.color
    }

    public appendRule(rule: Formula | null) {
      this.editedInsight!.rules!.push(rule!.id)

      this.editRule = true
    }

    public removeRule(rule: string) {
      this.editedInsight!.rules = this.editedInsight?.rules?.filter((r) => r !== rule) || []
    }

    public getCondition(split: boolean) {
      const rules = [
        this.editedInsight?.rules?.map((r) => this.findRule(r)?.condition.trim()).join(' && '),
        this.editedInsight?.rules?.length && this.editedInsight?.rule ? ' && ' : '',
        this.editedInsight?.rule || '',
      ]

      return split ? rules.filter(Boolean) : rules.join('')
    }

    private fetchTargetGroups() {
      this.insightTargetGroups = this.formulasStore.publishedGroups

      const allGroups = this.formulasStore.allGroups

      // add title from allGroups to insightTargetGroups
      this.insightTargetGroups.forEach((group) => {
        const allGroup = allGroups.find((g) => g.id === group.id)
        if (allGroup) {
          group.title = allGroup.title
        }
      })
    }

    private async updateTargetGroups() {
      if (!this.editedMessages || !this.originalMessages) {
        return
      }
      // select the messages that need updating
      let messagesToUpdate: any[] = []
      const uniqueGroups = this.formulasStore.uniqueTargetGroups(this.editedMessages)
      if (!isEqual(uniqueGroups, this.formulasStore.uniqueTargetGroups(this.originalMessages))) {
        messagesToUpdate = this.editedMessages
      } else {
        messagesToUpdate = this.editedMessages.filter((message) => {
          return (
            !isEqual(this.originalMessages?.find((m) => m.id === message.id)?.targetGroups, message.targetGroups) ||
            !this.originalMessages?.find((m) => m.id === message.id) ||
            this.originalMessages?.find((m) => m.id === message.id)?.targetGroupCondition !==
              message.targetGroupCondition
          )
        })
      }

      messagesToUpdate.forEach((message: Message) => {
        if (!this.editedMessages) {
          return
        }
        message.targetGroupCondition = this.formulasStore.getMessageGroupConditions(message.id, this.editedMessages)
        this.insightsStore.updateGroupConditionsByMessage(this.insight, message)
      })
      // Update insight parameter if used groups changes
      // Compare messages uniques with insight targetGroups

      if (
        uniqueGroups.length > 0 &&
        (this.insight.targetGroups === undefined ||
          this.insight.targetGroups?.length === 0 ||
          this.insight.targetGroups?.length !== uniqueGroups.length ||
          this.insight.targetGroups.filter((group: string) => !uniqueGroups.includes(group)).length > 0)
      ) {
        this.insightsStore.updateInsightByPath(this.messagePath.split('/messages')[0], {
          targetGroups: uniqueGroups,
        })
      }
    }
  }

  export default toNative(EditRuleDialog)
</script>
