<template>
  <v-dialog v-if="isOpen" v-model="isOpen" width="1000">
    <v-card>
      <v-card-title>Edit insights using the formula</v-card-title>

      <v-card-subtitle class="mt-n4 mb-4">{{ formulaId }}</v-card-subtitle>

      <v-card-text>
        <v-alert type="info" class="mb-6">
          To release the changes saved in this dialog you need to rollout the
          <b v-if="inRules">"Condition changes"</b>
          <b v-else-if="inEvals">"Spotlight changes"</b>
          project!
        </v-alert>

        <v-select v-model="tag" :items="appFeatureTags" label="Filter insights by tag" />

        <v-autocomplete
          v-model="selectedInsights"
          chips
          multiple
          return-object
          auto-select-first
          label="Insights using this formula"
          placeholder="No insights using this formula"
          :items="filteredInsights"
        >
          <template v-if="tag !== 'all'" #prepend-item>
            <v-list-item title="Select All" @click="toggleSelectAll()">
              <template #prepend>
                <v-checkbox :model-value="selectAllInsights" :indeterminate="!selectAllInsights" />
              </template>
            </v-list-item>

            <v-divider />
          </template>

          <template #chip="{ item, index }">
            <v-chip closable @click:close="selectedInsights.splice(index, 1)">
              {{ item.title }}
            </v-chip>
          </template>
        </v-autocomplete>

        <v-alert v-if="inEvals && insightsWithOtherSpotlight.length > 0" class="mb-4" color="warning">
          The following insights already have a spotlight category set:

          <div v-for="insight in insightsWithOtherSpotlight" :key="insight.id">{{ insight.id.toUpperCase() }}</div>
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Close" @click="((isOpen = false), (tag = 'all'))" />

        <v-btn text="Save" color="primary" :loading="isSaving" :disabled="!hasDataChanges" @click="saveChanges()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { AppStore, FormulasStore, InsightsStore, ProjectsStore } from '#stores'

  import { Insight } from '#types'

  @Component({})
  class InsightsDialog extends Vue {
    public tag = 'all'

    public title = ''
    public formulaId = ''

    public isOpen = false
    public isSaving = false

    public insights: any[] = []

    public selectedInsights: any[] = []

    public formulaInsights: Insight[] = []

    private readonly appStore = new AppStore()

    private readonly formulasStore = new FormulasStore()
    private readonly projectsStore = new ProjectsStore()
    private readonly insightsStore = new InsightsStore()

    public get inEvals() {
      return this.appStore.inEvals
    }

    public get inRules() {
      return this.appStore.inRules
    }

    public get allProjects() {
      return this.projectsStore.projects
    }

    public get appFeatureTags() {
      return [
        { title: 'All tags', value: 'all' },
        ...this.insightsStore.featureTags.map((t) => ({ title: t.text, value: t.value })),
      ]
    }

    public get hasDataChanges() {
      return (
        JSON.stringify(this.selectedInsights) !=
        JSON.stringify(this.formulaInsights.map((rule) => ({ text: rule.id, value: rule.id })))
      )
    }

    public get filteredInsights() {
      if (this.tag === 'all') {
        return this.insights.map((insight) => ({
          title: insight.id + (insight.state === 'archived' ? ' (ARCHIVED)' : ''),
          value: insight.id,
        }))
      } else {
        return this.insights
          .filter((insight) => insight.tags?.includes(this.tag))
          .map((insight) => ({
            title: insight.id + (insight.state === 'archived' ? ' (ARCHIVED)' : ''),
            value: insight.id,
          }))
      }
    }

    public get selectAllInsights() {
      return (
        this.filteredInsights.length > 0 &&
        this.filteredInsights.every((item) => this.selectedInsights.some((selected) => selected.value === item.value))
      )
    }

    public get insightsWithOtherSpotlight() {
      return this.insights.filter(
        (i) => i.spotlight && i.spotlight !== this.formulaId && this.selectedInsights.find((s) => s.value === i.id),
      )
    }

    public async open(formulaInsights: Insight[], formulaId: string, title: string) {
      this.title = title

      this.formulaId = formulaId
      this.formulaInsights = formulaInsights

      this.insights = await this.formulasStore.fetchFormulaInsights()

      this.selectedInsights = formulaInsights.map((f) => ({ title: f.id, value: f.id }))

      this.isOpen = true
    }

    public async saveChanges() {
      this.isSaving = true

      const add = this.selectedInsights
        .filter((i) => !this.formulaInsights.map((f) => f.id).includes(i.value))
        .map((i) => i.value)

      const remove = this.formulaInsights
        .filter((f) => !this.selectedInsights.map((item) => item.value).includes(f.id))
        .map((f) => f.id)

      for (const insight of this.insights) {
        if (this.inRules) {
          // This comment should not be changed!

          if (add.includes(insight.id) || remove.includes(insight.id)) {
            // Remove possible duplicates
            insight.rules = [...new Set(insight.rules)]

            if (add.includes(insight.id) && !insight.rules.includes(this.formulaId)) {
              insight.rules.push(this.formulaId)
            }

            if (remove.includes(insight.id)) {
              insight.rules.splice(insight.rules.indexOf(this.formulaId), 1)
            }

            await this.insightsStore.updateInsightCondition({
              id: insight.id,
              rule: insight.rule,
              rules: insight.rules,
            })
          }
        } else {
          if (add.includes(insight.id)) {
            await this.insightsStore.updateSpotlightCategory({
              id: insight.id,
              spotlight: this.formulaId,
            })
          } else if (remove.includes(insight.id)) {
            await this.insightsStore.updateSpotlightCategory({
              id: insight.id,
              spotlight: '',
            })
          }
        }
      }

      await this.formulasStore.updateFormula({
        id: this.formulaId,
        author: { ...this.appStore.author, comment: 'Updated insights using the formula' },
        insights: this.selectedInsights.map((i) => i.value).sort((a, b) => a.localeCompare(b)),
      })

      this.isSaving = false
      this.isOpen = false

      this.tag = 'all'
    }

    public toggleSelectAll() {
      if (this.selectAllInsights) {
        const insightValuesSet = new Set(this.filteredInsights.map((item) => item.value))

        this.selectedInsights = this.selectedInsights.filter((insight) => !insightValuesSet.has(insight.value))
      } else {
        const selectedInsightValuesSet = new Set(this.selectedInsights.map((insight) => insight.value))

        const insightsToAdd = this.filteredInsights.filter((item) => !selectedInsightValuesSet.has(item.value))

        this.selectedInsights = [...this.selectedInsights, ...insightsToAdd]
      }
    }
  }

  export default toNative(InsightsDialog)
</script>

<style lang="scss" scoped>
  :deep(.v-autocomplete:not(.v-input--is-focused).v-select--chips input) {
    max-height: 25px !important;
  }
</style>
