<template>
  <div v-if="message.facets.view" class="pt-4">
    <div class="text-primary text-h5">Background</div>

    <v-row v-if="message.facets.view.background" class="mt-2">
      <v-col cols="4">
        <v-select
          v-model="message.facets.view.background.type"
          label="Background type"
          :items="[
            { title: 'Image', value: 'image' },
            { title: 'Animation', value: 'animation' },
          ]"
        />
      </v-col>

      <v-col cols="8">
        <v-select
          v-if="message.facets.view.background.type === 'image'"
          clearable
          label="Background image"
          :model-value="(message.facets.view.background && message.facets.view.background.key) || ''"
          :items="messageBackgroundImages"
          @update:model-value="updateBackgroundValue('image', $event)"
        />

        <v-select
          v-if="message.facets.view.background.type === 'animation'"
          clearable
          label="Background animation"
          :model-value="(message.facets.view.background && message.facets.view.background.key) || ''"
          :items="messageBackgroundAnimations"
          @update:model-value="updateBackgroundValue('animation', $event)"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col>
        <v-btn
          prepend-icon="mdi-plus"
          class="mt-4 ml-n2"
          text="Add background "
          @click="message.facets.view.background = { type: 'image', key: '' }"
        />
      </v-col>
    </v-row>

    <div class="text-primary text-h5 my-6">Condition</div>

    <div class="d-flex flex-row align-center">
      <Statement v-model="message.condition" class="mb-n4" label="Condition preview" simplify readonly />

      <v-btn class="ml-2" icon="mdi-square-edit-outline" @click="editCondition()" />
    </div>

    <!--       
      append-icon="mdi-view-grid-outline"
      @click:append="galleryOpen = true"
    -->
    <!-- <FigmaGallery :open="galleryOpen" @select="updateBackground" /> -->
    <ComponentsList
      :insight="insight"
      :message="message"
      :template="template"
      :allowed-components="allowedComponents"
      :required-components="requiredComponents"
      :components="message.facets.view.components"
      @delete="deleteComponentAtIndex($event)"
      @remove-info="removeInfo($event)"
    />
  </div>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { messageBackgroundAnimations, messageBackgroundImages } from '#views/messages/constants'

  import { AppStore } from '#stores'

  import { Insight, Message, Template } from '#types'

  @Component({})
  class ViewFacet extends Vue {
    @Prop() public insight!: Insight
    @Prop() public message!: Message
    @Prop() public template!: Template

    @Prop({ default: () => [] }) public allowedComponents!: string[]
    @Prop({ default: () => [] }) public requiredComponents!: string[]

    @Emit('editCondition')
    public editCondition() {
      return this.message.id
    }

    public galleryOpen = false

    public messageBackgroundImages = messageBackgroundImages
    public messageBackgroundAnimations = messageBackgroundAnimations

    private appStore = new AppStore()

    public get inTemplates() {
      return this.appStore.inTemplates
    }

    public updateBackground(bg: any) {
      this.galleryOpen = false

      const keys = bg.name.toLowerCase().split(' ')

      if (isNaN(keys[keys.length - 1])) {
        this.message.facets.view!.background = {
          key: keys.slice(1).join('_'),
          type: 'image',
        }
      } else {
        this.message.facets.view!.background = {
          key: keys.slice(1, -1).join('_'),
          type: 'image',
          variant: parseInt(keys[keys.length - 1]),
        }
      }
    }

    public updateBackgroundValue(type: string, value: string) {
      if (value) {
        this.message.facets.view!.background = {
          key: value,
          type,
        }
      } else {
        this.message.facets.view!.background = undefined

        delete this.message.facets.view!.background
      }
    }

    public deleteComponentAtIndex(index: number) {
      this.message.facets.view!.components.splice(index, 1)

      /* if (!this.inTemplates && this.message.facets.view!.components.length == 0) {
        this.message.facets.view = null

        delete this.message.page
      } */
    }

    public removeInfo(idx: number) {
      if (!this.inTemplates) {
        this.message.facets.view!.components = this.message.facets.view!.components.map((c, i) => {
          if (idx == i) {
            delete c.info
          }

          return c
        })
      }
    }
  }

  export default toNative(ViewFacet)
</script>
