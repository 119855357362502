<template>
  <div class="my-6">
    <v-row>
      <v-col cols="4">
        <MessageItem
          v-if="selectedMessage"
          :type="type"
          :insight="insight"
          :message="selectedMessage"
          :title="selectedMessageId.split('/').pop() || ''"
          :snapshot-u-r-l="snapshotURLForMessageId(selectedMessageId)"
        />
      </v-col>
      <v-col cols="8">
        <v-data-table
          hide-default-header
          hide-default-footer
          items-per-page="-1"
          :items="messages"
          :headers="messageHeaders"
          :loading="isLoading"
        >
          <template #item.id="{ item }">
            <v-row style="cursor: pointer">
              <v-col>
                <v-chip
                  v-if="selectedMessageId === item.id && item.state"
                  variant="elevated"
                  :style="{ color: stateTextColor(item.state), background: stateBackgroundColor(item.state) }"
                  @click="selectMessage(item.id)"
                >
                  {{ item.id }}
                </v-chip>
                <v-chip
                  v-if="selectedMessageId !== item.id && item.state"
                  variant="plain"
                  :style="{ color: stateTextColor(item.state), background: stateBackgroundColor(item.state) }"
                  @click="selectMessage(item.id)"
                >
                  {{ item.id }}
                </v-chip>
              </v-col>
            </v-row>
          </template>
          <template #item.targetGroups="{ item }">
            <v-row style="width: 300px" @click="selectMessage(item.id)">
              <v-col>
                <v-autocomplete
                  variant="underlined"
                  chips
                  multiple
                  density="comfortable"
                  deletable-chips
                  auto-select-first
                  item-title="title"
                  item-value="id"
                  :color="item.state === 'draft' ? 'error' : 'primary'"
                  :model-value="item.targetGroups"
                  :return-object="false"
                  :items="insightTargetGroups"
                  :disabled="item.state === 'draft'"
                  @update:model-value="addGroup($event, item.id)"
                />
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-divider class="mt-4 mb-8" />

    <Statement :model-value="selectedMessage?.targetGroupCondition || ''" label="Saved condition" simplify readonly />
    <Statement v-model="previewCondition" label="Current selection" simplify readonly />

    <v-alert
      v-if="targetGroupsExist && !eligibleForProd"
      align="center"
      text="One catch all production message is required"
      class="mx-10"
      color="error"
    />

    <v-alert
      v-if="targetGroupsExist && !hasDefaultMessage && eligibleForProd"
      align="center"
      text="One releasable catch all message is required"
      class="mx-10"
      color="error"
    />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { messageStates } from '#views/insights/constants'

  import { FormulasStore } from '#stores'

  import { Insight, Message, MessageSnapshot } from '#types'
  import { InsightStateType, StateItem } from '#types'

  import { simplifyStatement } from '#statement'

  @Component({})
  class MessageGroupping extends Vue {
    @Prop() public insight!: Insight
    @Prop() public messages!: Message[]
    @Prop() public selectedMessagePath!: string
    @Prop() public messageSnapshots!: MessageSnapshot[]
    @Prop() public title!: string
    @Prop() public targetGroups!: any[]

    public isLoading = true
    public isOpen = false
    public isSaving = false

    public type = ''
    public messageId = ''
    public selectedMessageId: string = ''

    public selectedMessage: Message | undefined = undefined
    public previewCondition: string = ''

    public messageHeaders = [{ key: 'id' }, { key: 'targetGroups', width: 70, sortable: false }]
    public messageStates = messageStates
    public insightTargetGroups: any[] = []

    private formulaStore = new FormulasStore()

    public get targetGroupsExist() {
      // check if targetGroups exist in any of the messages
      return this.formulaStore.targetGroupsExist(this.messages)
    }

    public get hasDefaultMessage() {
      const releasableMessages = this.messages.filter((message) => this.messageReleasable(message))

      return releasableMessages.length === 0
        ? true
        : releasableMessages.filter((m) => m.targetGroups?.length === 0 || !m.targetGroups).length > 0
          ? true
          : false
    }

    public get eligibleForProd() {
      const prodMessages = this.messages.filter((message) => message.state === 'production')

      return prodMessages.length === 0
        ? true
        : prodMessages.filter((message) => message.targetGroups?.length === 0 || !message.targetGroups).length > 0
          ? true
          : false
    }

    public mounted() {
      this.isLoading = false
      this.isOpen = true

      this.type = 'targetEdit'
      this.messageId = this.selectedMessagePath.split('/').pop() || ''

      this.insightTargetGroups = this.targetGroups

      this.selectMessage(this.messageId)
    }

    public selectMessage(id: string) {
      this.selectedMessageId = id
      this.selectedMessage = this.messages.find((message) => message.id === id)
      this.updatePreviewConditions()
    }

    public messageReleasable(message: Message) {
      return message.state === 'experimental' || message.state === 'production'
    }

    public addGroup(groupSelection: string[], messageId: string) {
      const messageToChange = this.messages.find((message) => message.id === messageId)
      if (messageToChange) {
        messageToChange.targetGroups = groupSelection
      }
      this.updatePreviewConditions()
    }

    public groupNames(groupsIds: string[] | undefined) {
      return groupsIds
        ? this.insightTargetGroups.filter((group) => groupsIds.includes(group.id)).map((group) => group.title)
        : []
    }

    public snapshotURLForMessageId(id: string) {
      const snapshot = this.messageSnapshots.find((s) => s.id === id)
      return snapshot ? snapshot.url : undefined
    }

    public stateTextColor(state: InsightStateType): string {
      const stateItem = this.findStateItemForState(state)
      return stateItem ? stateItem.textColor : 'black'
    }

    public stateBackgroundColor(state: InsightStateType): string {
      const stateItem = this.findStateItemForState(state)
      return stateItem ? stateItem.color : 'grey'
    }

    private findStateItemForState(state: InsightStateType): StateItem | undefined {
      return this.messageStates.find((stateItem: StateItem) => stateItem.value === state)
    }

    private async updatePreviewConditions() {
      if (this.selectedMessage) {
        const result = (
          await simplifyStatement(this.formulaStore.getMessageGroupConditions(this.selectedMessage.id, this.messages))
        ).value
        this.previewCondition = result
      }
    }
  }

  export default toNative(MessageGroupping)
</script>
