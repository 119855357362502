<template>
  <div class="my-6">
    <div class="text-body-2 my-6">Message target grouppings:</div>
    <span v-if="!targetGroupsExist">No target grouppings defined for this message</span>
    <span v-else>
      <v-chip v-for="group in groupNames(message.targetGroups)" :key="group" class="ma-2">
        {{ group }}
      </v-chip>
    </span>

    <v-divider class="mt-4 mb-8" />

    <div class="text-body-2 my-6">Copy condition from another message:</div>
    <v-select
      v-model="copyFromMessage"
      clearable
      :items="otherMessages"
      placeholder="Do not copy condition for this message"
      no-data-text="No other messages to copy from"
    />

    <template v-if="!copyFromMessage">
      <v-divider class="mt-4 mb-8" />

      <div class="text-body-2 my-6">Only show this message with selected languages:</div>
      <v-select
        v-model="selectedLanguages"
        placeholder="Show this message for all app languages"
        :items="locales.filter((item) => item.value != '')"
        multiple
      />

      <div class="text-body-2 my-6">Only show this message with selected feature flags:</div>
      <v-select
        v-model="selectedFeatures"
        placeholder="Show this message regardless of feature flags"
        :items="flags.filter((item) => item.value != '')"
        multiple
      />

      <div class="text-body-2 my-6">Only show this message for selected app flavors:</div>
      <v-select
        v-model="selectedFlavors"
        placeholder="Show this message regardless of the app flavor"
        :items="flavors.filter((item) => item.value != '')"
        multiple
      />

      <template v-if="predefinedConditions.length">
        <v-divider class="mt-4 mb-8" />

        <div class="text-body-2 my-6">Visibility based on components:</div>

        <v-select
          v-model="selectedComponents"
          :items="predefinedConditions.map((c) => ({ text: c.description, value: c.condition }))"
          multiple
        />
      </template>

      <v-divider class="mt-4 mb-8" />

      <Statement
        v-if="targetGroupsExist"
        v-model="previewCondition"
        label="Group condition preview"
        simplify
        readonly
      />

      <Statement v-if="condition.length > 0" v-model="condition" label="Condition preview" simplify readonly />

      <v-alert
        v-if="multipleOptionsSelected"
        text="Caution when using groups and app settings together!"
        class="mt-2 mb-6"
        color="warning"
      />

      <v-checkbox v-model="hide" label="Hide message instead of showing (revert the condition behavior)" />
    </template>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { appFlavors, appLocales } from '#views/messages/constants'

  import { componentConditions } from '#views/messages/components/constants'

  import { FormulasStore } from '#stores/formulas.js'

  import { Message } from '#types'

  import { simplifyStatement } from '#statement'

  @Component({})
  class MessageCondition extends Vue {
    @Prop() public message!: Message
    @Prop() public messages!: Message[]
    @Prop() public targetGroups!: any[]

    public hide = false
    public targetGroupsExist = false

    public condition = ''
    public previewCondition = ''
    public groupCondition = ''
    public insightTargetGroups: any[] = []

    public flavors = appFlavors
    public locales = appLocales

    public copyFromMessage: string | null = null

    public selectedFeatures: any[] = []
    public selectedFlavors: any[] = []
    public selectedLanguages: any[] = []
    public selectedComponents: any[] = []

    public flags = [
      { title: 'Select feature flag', value: '' },
      {
        title: 'Bedtime guidance content',
        value: 'bedtime_guidance_content',
      },
    ]

    private readonly formulasStore = new FormulasStore()

    public get otherMessages() {
      return this.messages
        .map((item, index) => ({ title: `${index + 1}: ${item.id}`, value: item.id }))
        .filter((item) => item.value != this.message.id)
    }

    public get predefinedConditions() {
      return (this.message?.facets?.view?.components || [])
        .filter(
          (c) =>
            c[(componentConditions[c.type as keyof typeof componentConditions]?.key || 'type') as keyof typeof c] ===
            componentConditions[c.type as keyof typeof componentConditions]?.value,
        )
        .map((c) => componentConditions[c.type as keyof typeof componentConditions])
    }

    public get hasDefaultMessage() {
      return (
        this.messages.filter((message) => this.messageReleasable(message) && message.targetGroups?.length === 0)
          .length > 0
      )
    }

    public get multipleOptionsSelected() {
      return (
        this.targetGroupsExist &&
        (this.copyFromMessage !== null ||
          this.selectedLanguages.length ||
          this.selectedFeatures.length ||
          this.selectedFlavors.length)
      )
    }

    @Watch('hide')
    protected onHideChanged() {
      this.updateConditions()
    }

    @Watch('condition')
    protected onUserChanged() {
      this.message.additionalCondition = this.condition
    }

    @Watch('message')
    protected onMessageChanged() {
      this.updateConditions()
    }

    @Watch('copyFromMessage')
    protected copyFromMessageChanged() {
      this.updateConditions()
    }

    @Watch('selectedFlavors')
    protected selectedFlavorsChanged() {
      this.updateConditions()
    }

    @Watch('selectedFeatures')
    protected selectedFeaturesChanged() {
      this.updateConditions()
    }

    @Watch('selectedLanguages')
    protected selectedLanguagesChanged() {
      this.updateConditions()
    }

    @Watch('selectedComponents')
    protected selectedComponentsChanged() {
      this.updateConditions()
    }

    public mounted() {
      this.copyFromMessage = this.message.conditionSource || null
      this.insightTargetGroups = this.targetGroups
      if (this.formulasStore.targetGroupsExist(this.messages)) {
        this.targetGroupsExist = true
        this.groupCondition = this.message.targetGroupCondition || ''
        this.previewCondition = this.groupCondition
      }

      if (this.message && 'additionalCondition' in this.message) {
        const condition = String(this.message.additionalCondition)

        this.selectedFeatures = condition
          .split(' && ')
          .filter((condition) => /app.feature_enabled/gi.test(condition))
          .flatMap((condition) => {
            if (/\|\|/gi.test(condition)) {
              return condition.split(' || ').map((item: any) => item.match(/app.feature_enabled\('([a-z_]+)'\)/)[1])
            } else if (condition !== null) {
              let match = condition.match(/app.feature_enabled\('([a-z_]+)'\)/)
              return match ? match[1] : ''
            }
          })
          .map((condition) => this.flags.find((item) => item.value == condition))
          .map((item) => item?.value)

        this.selectedFlavors = condition
          .split(' && ')
          .filter((condition) => /app.flavor/gi.test(condition))
          .flatMap((condition) => {
            if (/\|\|/gi.test(condition)) {
              return condition.split(' || ').map((item: any) => item.match(/app.flavor === '([a-z_]+)'/)[1])
            } else if (condition !== null) {
              let match = condition.match(/app.flavor === '([a-z_]+)'/)
              return match ? match[1] : ''
            }
          })
          .map((condition) => appFlavors.find((item) => item.value == condition))
          .map((item) => item?.value)

        this.selectedLanguages = condition
          .split(' && ')
          .filter((condition) => /app.isoLanguage/gi.test(condition))
          .flatMap((condition) => {
            if (/\|\|/gi.test(condition)) {
              return condition.split(' || ').map((item: any) => item.match(/app.isoLanguage === '([a-z_]+)'/)[1])
            } else if (condition !== null) {
              let match = condition.match(/app.isoLanguage === '([a-z_]+)'/)
              return match ? match[1] : ''
            }
          })
          .map((condition) => appLocales.find((item) => item.value == condition))
          .map((item) => item?.value)

        this.selectedComponents = this.predefinedConditions
          .map((c) => c.condition)
          .filter((c) => condition.includes(`${c}`))

        this.hide = condition.startsWith('!')

        this.condition = condition
        this.previewCondition = this.targetGroupsExist ? this.groupCondition + ' && ' + condition : condition
      }
    }

    public groupNames(groupsIds: string[] | undefined) {
      return groupsIds
        ? this.insightTargetGroups.filter((group) => groupsIds.includes(group.id)).map((group) => group.title)
        : []
    }

    public messageReleasable(message: Message) {
      return message.state === 'experimental' || message.state === 'production'
    }

    public async updateConditions() {
      let condition: any = []
      let previewCondition: any = []

      if (this.selectedFeatures.length) {
        condition.push(
          `(${this.selectedFeatures
            .filter((val) => val.length)
            .map((val) => `app.feature_enabled('${val}')`)
            .join(' || ')})`,
        )
      }

      if (this.selectedFlavors.length) {
        condition.push(
          `(${this.selectedFlavors
            .filter((val) => val.length)
            .map((val) => `app.flavor === '${val}'`)
            .join(' || ')})`,
        )
      }

      if (this.selectedLanguages.length) {
        condition.push(
          `(${this.selectedLanguages
            .filter((val) => val.length)
            .map((val) => `app.isoLanguage === '${val}'`)
            .join(' || ')})`,
        )
      }

      if (this.selectedComponents.length) {
        condition.push(`${this.selectedComponents.join(' && ')}`)
      }

      if (this.targetGroupsExist) {
        this.groupCondition = this.message.targetGroupCondition || ''
        this.previewCondition = this.groupCondition
      }

      condition = condition.join(' && ')

      if (this.hide) {
        condition = `!(${condition})`
      }

      this.message.conditionSource = this.copyFromMessage || ''

      const result = (await simplifyStatement(condition)).value
      this.condition = result
      if (previewCondition.length > 0 && result.length > 0) {
        this.previewCondition = this.groupCondition + ' && ' + result
      }
    }
  }

  export default toNative(MessageCondition)
</script>
