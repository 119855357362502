import dayjs from 'dayjs'

import { sortBy } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import { Unsubscribe, onSnapshot, serverTimestamp } from 'firebase/firestore'

import { collectionRef, setFsDocData, updateFsDocData } from '#common/firebase'

import { RolloutsStore } from '#stores'

import { WaltariProject } from '#types'

let unsubscribeProjects: Unsubscribe | undefined = undefined

@Store
export class ProjectsStore extends Pinia {
  public loading = true
  public updating = true

  public project: WaltariProject | null = null

  public projects: WaltariProject[] = []

  public published: WaltariProject[] = []

  public async setProject(project: WaltariProject | null) {
    this.project = project

    localStorage.OuraProject = project?.id ?? ''
  }

  public async updateProject(project: WaltariProject) {
    const updatedProject = {
      ...project,
      ...(project.createdAt ? {} : { createdAt: serverTimestamp() }),
    }

    await setFsDocData('projects', project.id, updatedProject)
  }

  public async updatePublishedProjects() {
    this.updating = true

    const rolloutsStore = new RolloutsStore()

    const lastRolloutDate = await rolloutsStore.lastRolloutDate()

    this.published = this.projects.filter((p) =>
      dayjs((p.releasedAt?.seconds || 0) * 1000).isAfter(dayjs(lastRolloutDate)),
    )

    this.published = sortBy(this.published, [(o: any) => o.name || ''])

    this.updating = false
  }

  public async resetChangesAtTimestamp(project: WaltariProject) {
    const change = {
      changesAt: project?.releasedAt || project?.createdAt || '',
    }

    await updateFsDocData('projects', project.id, change)
  }

  public async subscribeToProjects() {
    if (!unsubscribeProjects) {
      unsubscribeProjects = onSnapshot(collectionRef('projects'), (snap) => {
        this.projects = sortBy(
          snap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            // TODO: Temporal migration code for project types, should run mass migration at some point
            sections: {
              content: doc.data().sections?.content ?? (doc.data().sections?.contents || doc.data().sections?.insights),
              features: doc.data().sections?.features,
            },
          })),
          [(o: any) => o.name || ''],
        )

        this.project = this.projects.find((p: WaltariProject) => p.id === localStorage.OuraProject) || null

        this.loading = false
      })
    }
  }
  public async unsubscribeFromProjects() {
    this.project = null

    this.projects = []

    if (unsubscribeProjects) {
      unsubscribeProjects()

      unsubscribeProjects = undefined
    }
  }
}
