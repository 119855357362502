import { chunk, groupBy, pick } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import { getApp } from 'firebase/app'
import {
  Unsubscribe,
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  writeBatch,
} from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'

import { compareVersions } from 'compare-versions'

import { AppStore } from '#stores'

let unsubscribeRollouts: Unsubscribe | undefined = undefined

@Store
export class RolloutsStore extends Pinia {
  public type = ''

  public saving = false
  public loading = false
  public releasing = false

  public metadata: any = {}

  public actions: any[] = []
  public rollouts: any[] = []

  public async listOtaRollouts(type: string) {
    this.type = type

    if (!this.rollouts.length) {
      this.loading = true
    }

    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(functions, 'listOtaRolloutsFromFilero')({ type })

    if (this.type === type) {
      this.rollouts = (response.data?.items || [])
        .filter((r: any) => r.state === 'active' || r.state === 'disabled')
        .sort((a: any, b: any) => compareVersions(b.value.version, a.value.version))
    }

    this.loading = false

    return response
  }

  public async enableOtaRollout(type: string, slug: string) {
    this.loading = true

    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(functions, 'modifyOtaRolloutInFilero')({ type, slug, state: 'active' })

    this.loading = false

    this.listOtaRollouts(type)

    return response
  }

  public async disableOtaRollout(type: string, slug: string) {
    this.loading = true

    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(functions, 'modifyOtaRolloutInFilero')({ type, slug, state: 'disabled' })

    this.loading = false

    this.listOtaRollouts(type)

    return response
  }

  public async updateReleaseData(releaseData: any) {
    this.saving = true

    const operations: any[] = []

    const releaseProject = await getDoc(doc(getFirestore(), `/projects/${releaseData.project}`))

    const minor = releaseProject.data()?.releasedAt ? 0 : 1
    const patch = releaseProject.data()?.releasedAt ? 1 : 0

    if (releaseData.project) {
      const releaseTypes = ['contents', 'insights', 'tipthemes', 'slideshows', 'mappings-spotlight-modes']

      for (const type of releaseTypes) {
        const latestRelease = await getDoc(doc(getFirestore(), `/rollouts/${type}`))

        const releasesInfo: any = latestRelease.data() || {}

        let translationsData = releaseData.translations?.filter((t: any) => t.type === type)

        if (releaseData[type] || translationsData.length) {
          if (type === 'mappings-spotlight-modes') {
            for (const data of releaseData[type]) {
              operations.push({
                ref: doc(getFirestore(), `/rollouts-spotlight-mappings/${data.id}`),
                data: pick(data, ['id', 'name', 'order', 'condition', 'day', 'morning', 'evening', 'overnight']),
              })
            }
          } else {
            const version = (releasesInfo.releasedVersion || '1.0.0').split('-')[0].split('.')

            const newVersion = `${version[0]}.${parseInt(version[1]) + minor}.${minor ? 0 : parseInt(version[2]) + patch}`

            operations.push({
              ref: doc(getFirestore(), `/rollouts/${type}`),
              data: {
                releasedAt: serverTimestamp(),
                releasedProject: releaseData.project,
                releasedVersion: newVersion,
              },
            })
          }

          if (releaseData[type]) {
            if (releaseData[type].content) {
              for (const data of releaseData[type].content) {
                operations.push({
                  ref: doc(getFirestore(), `/rollouts/${type}/content`, data.content.id),
                  data: data.content,
                  merge: true,
                })
              }
            }

            if (releaseData[type].locale) {
              for (const data of releaseData[type].locales) {
                const groups = groupBy(Object.entries(data.messages), (l) => l[0].split(':')[0])

                for (const [id, locales] of Object.entries(groups)) {
                  const texts = {}

                  locales.forEach((d) => Object.assign(texts, { [d[0]]: d[1] }))

                  operations.push({
                    ref: doc(getFirestore(), `/rollouts/${type}/translations/${id}/locales`, data.language),
                    data: texts,
                    merge: true,
                  })
                }

                translationsData = translationsData.filter(
                  (t: any) => t.language !== data.language || !data.messages[t.id],
                )
              }
            }

            if (releaseData[type].relations) {
              for (const data of releaseData[type].relations) {
                operations.push({
                  ref: doc(getFirestore(), `/rollouts/${type}/relations`, data.relations.id),
                  data: data.relations,
                })
              }
            }
          }

          if (translationsData.length) {
            for (const [language, messages] of Object.entries(groupBy(translationsData, (o) => o.language))) {
              const groups = groupBy(messages, (m) => m.id.split(':')[0])

              for (const [id, locales] of Object.entries(groups)) {
                const texts = {}

                locales.forEach((l) => Object.assign(texts, { [l.id]: l.message }))

                operations.push({
                  ref: doc(getFirestore(), `/rollouts/${type}/translations/${id}/locales`, language),
                  data: texts,
                  merge: true,
                })
              }
            }
          }
        }
      }

      for (const batch of chunk(operations, 500)) {
        const db = writeBatch(getFirestore())

        batch.forEach((b) => db.set(b.ref, b.data, { merge: b.merge || false }))

        await db.commit()
      }

      await updateDoc(doc(getFirestore(), `/projects/${releaseData.project}`), {
        releasedAt: serverTimestamp(),
      })
    }

    this.saving = false

    return !!releaseData.project
  }

  public async rolloutAndActivate() {
    this.saving = true

    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(functions, 'rolloutAndActivateInFilero', { timeout: 300000 })({})

    this.saving = false

    return response
  }

  /**
   * Checks the status of the GitHub Actions for the Insight Engine and the Insight Content.
   * Returns undefined if the user is not an OTA Content Admin.
   */
  public async checkGitHubStatuses() {
    let response: any

    const functions = getFunctions(getApp(), 'europe-west1')

    response = await httpsCallable(functions, 'releaseInsightEngineInGitHub')({ check: true })

    this.actions = [...(response.data.workflow_runs || [])]

    response = await httpsCallable(functions, 'rolloutInsightContentInGitHub')({ check: true })

    this.actions = this.actions.concat(response.data.workflow_runs || [])

    return this.actions
  }

  public async lastRolloutDate() {
    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(
      functions,
      'rolloutInsightContentInGitHub',
    )({ check: true, status: 'success' })

    return response.data.workflow_runs?.[0]?.created_at
  }

  public async productionOtaRelease(data: any) {
    this.loading = true

    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(functions, 'releaseInsightEngineInGitHub')(data)

    this.loading = false

    return response
  }

  public async experimentalOtaRollout(data?: any) {
    this.saving = true

    const appStore = new AppStore()

    data.creator = appStore.user?.email

    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(functions, 'rolloutInsightContentInGitHub')(data)

    this.saving = false

    return response
  }

  public async privateGroupOtaRollout(data: any) {
    this.loading = true

    const appStore = new AppStore()

    data.creator = appStore.user?.email
    data.filters = 'label:criteria:ota-content-private-users'

    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(functions, 'rolloutInsightContentInGitHub')(data)

    this.loading = false

    return response
  }

  public async subscribeToRollouts() {
    if (!unsubscribeRollouts) {
      const metadata: any = {}

      unsubscribeRollouts = onSnapshot(query(collection(getFirestore(), '/releases/apps/ota')), (snap) => {
        snap.docs.forEach((doc) => (metadata[doc.id] = doc.data()))

        this.metadata = metadata
      })
    }
  }
  public async unsubscribeFromRollouts() {
    if (unsubscribeRollouts) {
      unsubscribeRollouts()

      unsubscribeRollouts = undefined
    }
  }
}
