import { Timestamp } from 'firebase/firestore'

import { Author, ContentOrigin, InsightStateType, LanguageId, MessageStateType, Translatable } from '#types'

export type DeltaCategory = 'sleep_score' | 'readiness_score' | 'total_sleep_time'

export type BackgroundType = 'image' | 'animation'

export type BadgeRewardType = 'superpower' | 'kryptonite' | 'improver'

export type MessageHomeFacetActionType = 'cta_primary' | 'cta_secondary'

export type MessageHomeFacetPlaceholders = 'body' | 'title'

export type MessageHomeFacetDisplayStyle = 'inferred' | 'action_card'

export type MessageViewComponent =
  | UIComponentActivityIntensity
  | UIComponentButton
  | UIComponentChart
  | UIComponentContributor
  | UIComponentContributors
  | UIComponentHint
  | UIComponentIdealBedtimeChart
  | UIComponentLabel
  | UIComponentMeasurementStack
  | UIComponentNightTimeHeartRate
  | UIComponentNightTimeHeartRateVariability
  | UIComponentParagraph
  | UIComponentQuestionnaire
  | UIComponentRichText
  | UIComponentScore
  | UIComponentSetting
  | UIComponentSleepClock
  | UIComponentSleepStages
  | UIComponentTitle
  | UIComponentDelta
  | UIComponentPicture
  | UIComponentContentRecommendation

export interface Category {
  value: string
  title: string
}

export interface Insight {
  id: string
  type?: string
  project?: string | null
  condition?: string
  createdAt?: Timestamp
  updatedAt?: Timestamp
  publishedAt?: Timestamp
  state?: InsightStateType
  originalId?: string
  originalAuthor?: Author
  author?: Author
  priority: string
  spotlight?: string
  group_id: string
  description: string
  category: string
  minimum_interval: number
  minimum_category_interval: number
  tags: string[]
  silent: boolean
  transient: boolean
  origin: ContentOrigin
  rule: string
  rules: string[]
  targetGroups?: string[]
  tips?: string[]
  notes?: number
  duration?: number
  snooze_period_days?: number
  relations_tips_limit?: number
}

export interface Message {
  id: string
  order: number
  facets: MessageFacets
  origin: ContentOrigin

  state?: MessageStateType
  author?: Author
  createdAt?: Timestamp
  updatedAt?: Timestamp
  media?: MediaItemType[]
  links?: LinksItemType[]
  notes?: number
  targetGroups?: string[]
  targetGroupCondition?: string
  template?: string
  type?: string
  page?: number
  project?: string | null
  condition?: string
  conditionSource?: string
  additionalCondition?: string
  info?: string
  originalId?: string
  originalAuthor?: Author
  generated?: boolean
  usedPrompt?: boolean
}

export interface Template {
  id: string
  name: string
  createdAt?: Timestamp
  updatedAt?: Timestamp
  requiredComponents: string[]
  allowedComponents: string[]
  allowedFacets: string[]
  allowedPages: string[]
  author: Author
  facets: MessageFacets
  icon?: string
  info?: string
  category?: string
  targetGroups?: string[]
}

export interface LinksItemType {
  id: string
  type: 'web' | 'zendesk' | 'slideshow'
  title: Translatable
  value: string
  variants?: { [key in LanguageId]: string }
}

export interface MediaItemType {
  type: 'video' | 'slideshow'
  duration: number
  slug: string
  id: string
}

export interface MessageFacets {
  view: MessageViewFacet | null
  home: MessageHomeFacet | null
  notification: MessageNotificationFacet | null
}

export interface MessageHomeFacet {
  header: Translatable
  title: Translatable
  body: Translatable
  background?: BackgroundObject
  display: MessageHomeFacetDisplayStyle
  actions: MessageHomeFacetAction[]
  components: MessageViewComponent[]
  placeholders?: {
    body?: UIComponentRichTextFormatPlaceholderMatch[]
    title?: UIComponentRichTextFormatPlaceholderMatch[]
  }
}

export interface MessageViewFacet {
  background?: BackgroundObject
  components: MessageViewComponent[]
}

export interface MessageHomeFacetAction {
  type: MessageHomeFacetActionType
  id: string
  condition: string | null
  analytics_id: string | null
  title: Translatable
  url?: string
}

export interface MessageNotificationFacet {
  title: Translatable
  body: Translatable
}

export interface BackgroundObject {
  key: string
  variant?: string | number
  type?: BackgroundType | string
}

export interface BadgeRewardObject {
  id: string
  components: MessageViewComponent[]
}

export type CreateMessageProps = {
  template?: Template
  type?: string
  messages: Message[]
  insightsPath: string
  insight: any
  page?: number
  insightPath: string
}

export interface ComponentMapping {
  type: UIComponentType
  component: any
  previewComponent: any
  icon: string
  category?: string
}

export enum UIComponentType {
  delta = 'delta',
  activityIntensity = 'activity_intensity',
  button = 'button',
  chart = 'chart',
  contributor = 'contributor',
  contributors = 'contributors',
  hint = 'hint',
  idealBedtimeChart = 'ideal_bedtime_chart',
  label = 'label',
  measurementStack = 'measurement_stack',
  nightTimeHeartRate = 'night_time_heart_rate',
  nightTimeHRV = 'night_time_hrv',
  paragraph = 'paragraph',
  questionnaire = 'questionnaire',
  richText = 'rich_text',
  score = 'score',
  setting = 'setting',
  sleepClock = 'sleep_clock',
  sleepStages = 'sleep_stages',
  title = 'title',
  picture = 'picture',
  contentRecommendation = 'content_recommendation',
  badgeReward = 'badge_reward',
}

export type UIComponentDelta = UIComponentDefault<UIComponentType.delta> & {
  category: DeltaCategory
}

export type UIComponentDefault<UIComponentType> = {
  readonly id: string
  readonly type: UIComponentType
  info?: string
}

export type UIComponentDefaultText<UIComponentType> = UIComponentDefault<UIComponentType> & {
  text: Translatable
}

export type UIComponentDefaultTitle<UIComponentType> = UIComponentDefault<UIComponentType> & {
  title: Translatable
}

export type UIComponentSleepClock = UIComponentDefault<UIComponentType.sleepClock>

export type UIComponentHint = UIComponentDefaultText<UIComponentType.hint>

export type UIComponentTitle = UIComponentDefaultTitle<UIComponentType.title>

export type UIComponentSleepStages = UIComponentDefaultTitle<UIComponentType.sleepStages> & {
  show_movement_graph: boolean
}

export type UIComponentSetting = UIComponentDefault<UIComponentType.setting> & {
  setting: string
}

export type UIComponentScore = UIComponentDefaultText<UIComponentType.score> & {
  category: string
}

export interface UIComponentRichTextFormatPlaceholderMatch {
  index: number
  offset?: number
  statement: string
}
export type UIComponentRichText = UIComponentDefault<UIComponentType.richText> & {
  plain_text?: boolean
  contents: Translatable
  placeholders: UIComponentRichTextFormatPlaceholderMatch[]
}

export interface UIComponentQuestionnaireOption {
  id: string
  mnemonic: string
  name: Translatable
  condition?: string
}

export type UIComponentQuestionnaire = UIComponentDefault<UIComponentType.questionnaire> & {
  mnemonic: string
  select_min: number
  select_max: number
  options: UIComponentQuestionnaireOption[]
}

export type UIComponentParagraph = UIComponentDefault<UIComponentType.paragraph> & {
  contents: Translatable
}

export type UIComponentNightTimeHeartRateVariability = UIComponentDefaultTitle<UIComponentType.nightTimeHRV>

export type UIComponentNightTimeHeartRate = UIComponentDefaultTitle<UIComponentType.nightTimeHeartRate>

export type UIComponentMeasurementStack = UIComponentDefault<UIComponentType.measurementStack> & {
  measurements: string[]
}

export type UIComponentLabel = UIComponentDefaultText<UIComponentType.label> & {
  style: string
  javascript: boolean
}

export type UIComponentIdealBedtimeChart = UIComponentDefaultTitle<UIComponentType.idealBedtimeChart>

export type UIComponentContributors = UIComponentDefault<UIComponentType.contributors> & {
  source: string
}

export type UIComponentContributor = UIComponentDefaultTitle<UIComponentType.contributor> & {
  contributor: string
}

export type UIComponentChart = UIComponentDefault<UIComponentType.chart> & {
  presentation: string
  mood: string
  measurement: string
  interval: string
  length: number
  average_line: string
  offset: number
  hide_x_axis?: boolean
  title?: Translatable | null
}

export type UIComponentButton = UIComponentDefaultTitle<UIComponentType.button> & {
  analytics_id: string
  url: string
  style?: string
  prepend_icon?: string
  append_icon?: string
}

export type UIComponentPicture = UIComponentDefault<UIComponentType.picture> & {
  image: string
}

export type UIComponentBadgeReward = UIComponentDefault<UIComponentType.badgeReward> & {
  count: number
  reward: BadgeRewardType
  badges: BadgeRewardObject[]
  template?: string
}

export type UIComponentContentRecommendation = UIComponentDefault<UIComponentType.contentRecommendation> & {
  counter: string
  contents: ContentRecommendationSettings[]
}

export type UIComponentActivityIntensity = UIComponentDefaultTitle<UIComponentType.activityIntensity>

export interface ContentRecommendationSettings {
  days: number
  text: Translatable
  content: string
}

export type InsightCollection = 'insights' | 'tipthemes' | 'slideshows'

export type StateItem = {
  value: InsightStateType
  title: string
  color: string
  textColor: string
  weight?: number
  disabled?: boolean
}

export type InsightChartData = {
  'date': string
  'viewed': number
  'clicked': number
  'generated': number
  'android viewed': number
  'android clicked': number
  'android generated': number
  'ios viewed': number
  'ios clicked': number
  'ios generated': number
}
