<template>
  <v-dialog persistent width="600" :model-value="true">
    <v-card>
      <v-card-title>Confirm rollout creation</v-card-title>

      <v-card-text class="pt-2">
        <div v-if="loading" class="mb-4">
          Checking for published projects for the rollout, this should not take very long...
        </div>

        <v-progress-linear v-if="loading" indeterminate />

        <v-alert
          v-else-if="!publishedProjects.length"
          color="info"
          text="There are no new published projects since the previous OTA rollout, you need to publish the changes before making a rollout!"
        />

        <template v-else>
          The following projects have published changes since last OTA rollout was made and will be included in this
          rollout:
        </template>

        <v-list>
          <v-list-item
            v-for="project in publishedProjects"
            :key="project.id"
            :title="project.name"
            :subtitle="'Released on ' + $dayjs(project.releasedAt!.seconds * 1000).format('DD MMM YYYY')"
          />
        </v-list>
      </v-card-text>

      <v-card-actions style="overflow: hidden">
        <v-spacer />

        <v-btn text="Cancel" :disabled="loading || releasing" @click="close()" />
        <v-btn
          text="Confirm"
          color="primary"
          :loading="releasing"
          :disabled="loading || releasing || !publishedProjects.length"
          @click="confirmRollout()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Vue, toNative } from 'vue-facing-decorator'

  import { ProjectsStore, RolloutsStore } from '#stores'

  @Component({})
  class RolloutDialog extends Vue {
    @Emit('close')
    public close() {
      return true
    }

    public readonly env = import.meta.env.VITE_APP_ENV

    private readonly projectsStore = new ProjectsStore()

    private readonly rolloutsStore = new RolloutsStore()

    public get loading() {
      return this.projectsStore.loading || this.projectsStore.updating
    }

    public get releasing() {
      return this.rolloutsStore.releasing
    }

    public get activeProject() {
      return this.projectsStore.project
    }

    public get publishedProjects() {
      return this.projectsStore.published
    }

    public mounted() {
      this.projectsStore.updatePublishedProjects()
    }

    public async confirmRollout() {
      this.rolloutsStore.releasing = true

      const actions = await this.rolloutsStore.checkGitHubStatuses()

      if (!actions?.length) {
        await this.rolloutsStore.rolloutAndActivate()

        if (this.env === 'release') {
          await this.rolloutsStore.experimentalOtaRollout()
        }
      }

      this.close()
    }
  }

  export default toNative(RolloutDialog)
</script>
