import { pick, sortBy } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import {
  Unsubscribe,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'

import { createDefaultFormula, createDefaultMode } from '#views/formulas/utilities'

import { AppStore, ProjectsStore } from '#stores'

import { Message } from '#types'

let unsubscribeEvals: Unsubscribe | undefined = undefined
let unsubscribeModes: Unsubscribe | undefined = undefined
let unsubscribeRules: Unsubscribe | undefined = undefined
let unsubscribeGroups: Unsubscribe | undefined = undefined
let unsubscribePublishedGroups: Unsubscribe | undefined = undefined

@Store
export class FormulasStore extends Pinia {
  public loading = false

  public allEvals: any[] = []
  public allModes: any[] = []
  public allRules: any[] = []
  public allGroups: any[] = []
  public publishedGroups: any[] = []

  public get evals() {
    return this.allEvals.filter((r) => r.releasedAt)
  }

  public get rules() {
    return this.allRules.filter((r) => r.releasedAt)
  }

  public get formulas() {
    const inEvals = new AppStore().inEvals
    const inRules = new AppStore().inRules
    const inGroups = new AppStore().inGroups

    return inEvals ? this.allEvals : inRules ? this.allRules : inGroups ? this.allGroups : []
  }

  public async fetchRules(ruleIds: string[]) {
    const rules = await getDocs(query(collection(getFirestore(), `/rules`), where('id', 'in', ruleIds)))

    return rules.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as any[]
  }

  public async createFormula(formula: any) {
    const appStore = new AppStore()
    const projectsStore = new ProjectsStore()

    formula.author = appStore.author

    formula.createdAt = serverTimestamp()
    formula.updatedAt = serverTimestamp()

    if (projectsStore.project) {
      formula.project = projectsStore.project.id
    }

    const rootPath = appStore.inEvals ? 'evals' : appStore.inRules ? 'rules' : 'formulas-message-configs'

    return await setDoc(doc(getFirestore(), `/${rootPath}/${formula.id}`), formula)
  }
  public async updateFormula(formula: any) {
    const appStore = new AppStore()

    // TODO: When mappings are updated nothing really changes for the formula
    //       and we should move the mappings out of the content / formula data.

    if (!formula.insights) {
      formula.updatedAt = serverTimestamp()
    }

    const rootPath = appStore.inEvals ? 'evals' : appStore.inRules ? 'rules' : 'formulas-message-configs'

    return await updateDoc(doc(getFirestore(), `/${rootPath}/${formula.id}`), formula)
  }
  public async deleteFormula(formulaId: string) {
    const appStore = new AppStore()

    const rootPath = appStore.inEvals ? 'evals' : appStore.inRules ? 'rules' : 'formulas-message-configs'

    return await deleteDoc(doc(getFirestore(), `/${rootPath}/${formulaId}`))
  }

  public async publishFormula(formula: any) {
    const appStore = new AppStore()
    const projectsStore = new ProjectsStore()

    const rootPath = appStore.inEvals ? 'evals' : appStore.inRules ? 'rules' : 'formulas-message-configs'

    await updateDoc(doc(getFirestore(), `/${rootPath}/${formula.id}`), {
      author: { ...appStore.author, comment: 'Published formula' },
      releasedAt: serverTimestamp(),
    })

    const releaseData = pick(formula, ['id', 'type', 'order', 'category', 'condition', 'subcategory'])

    if (appStore.inRules) {
      return await setDoc(doc(getFirestore(), `/rollouts/rules/content/${formula.id}`), releaseData)
    } else if (appStore.inEvals) {
      const project = projectsStore.projects.find((p) => p.name === 'Spotlight changes')

      await updateDoc(doc(getFirestore(), `/projects/${project?.id || formula.project}`), {
        releasedAt: serverTimestamp(),
      })

      return await setDoc(doc(getFirestore(), `/rollouts-spotlight-configs/${formula.id}`), releaseData)
    } else if (appStore.inGroups) {
      return await setDoc(doc(getFirestore(), `/rollouts-messaging-grouppings/${formula.id}`), releaseData)
    }
  }

  public async unpublishFormula(formulaId: string) {
    const appStore = new AppStore()

    if (appStore.inRules) {
      return await deleteDoc(doc(getFirestore(), `/rollouts/rules/content/${formulaId}`))
    } else if (appStore.inEvals) {
      return await deleteDoc(doc(getFirestore(), `/rollouts-spotlight-configs/${formulaId}`))
    } else {
      return await deleteDoc(doc(getFirestore(), `/rollouts-messaging-grouppings/${formulaId}`))
    }
  }

  public async listSpotlightModes(ids: string[]) {
    const modes = await getDocs(query(collection(getFirestore(), `/mappings-spotlight-modes`), where('id', 'in', ids)))

    return modes.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
  }

  public async updateSpotlightMode(mode: any) {
    const appStore = new AppStore()

    await updateDoc(doc(getFirestore(), `/mappings-spotlight-modes/${mode.id}`), {
      author: { ...appStore.author, comment: 'Spotlight modes updated' },
      ...mode,
    })
  }

  public uniqueTargetGroups(messages: Message[]) {
    const uniques = messages.reduce((uniques: string[], m: any) => {
      m.targetGroups?.forEach((group: string) => {
        if (!uniques.includes(group)) {
          uniques.push(group)
        }
      })
      return uniques
    }, [] as string[])
    return uniques.sort()
  }

  public targetGroupsExist(messages: Message[]) {
    return messages.filter((message) => !!message.targetGroups && message.targetGroups.length > 0).length > 0
  }

  public getGroupConditions(message: Message, uniqueGroups: string[]) {
    // filter published groups to only include groups that are in use
    const uniqueGroupItems = this.publishedGroups.filter((group) => uniqueGroups.includes(group.id))

    const conditions = uniqueGroupItems.map(function (group) {
      if (message.targetGroups?.includes(group.id)) {
        return group.condition
      } else {
        if (group.condition.includes(' ')) {
          return `!(${group.condition})`
        } else {
          return `!${group.condition}`
        }
      }
    })

    return conditions.join(' && ')
  }

  public getMessageGroupConditions(messageId: string, messages: Message[]) {
    //const originalMessages = cloneDeep(messages)
    const message = messages.filter((m) => m.id === messageId)[0]
    if (message === undefined) {
      return ''
    }

    const prod = messages.filter((m) => m.state === 'production')
    const exp = messages.filter((m) => m.state === 'experimental')
    const uniqueGroups = this.uniqueTargetGroups(messages)
    if (!(prod.length > 0 && exp.length > 0)) {
      return this.getGroupConditions(message, uniqueGroups)
    }
    // Create condition for message within multiflavor insight
    const prodUniques = this.uniqueTargetGroups(prod)
    const prodTargetedMessages = messages.filter(
      (m) => m.state === 'production' && m.targetGroups && m.targetGroups.length > 0,
    )
    const expTargetedMessages = messages.filter(
      (m) => m.state === 'experimental' && m.targetGroups && m.targetGroups.length > 0,
    )

    const prodFlavorCondition = "app.flavor === 'release'"
    const otherFlavorCondition = "app.flavor !== 'release'"
    switch (message.state) {
      case 'production':
        return (
          '(' +
          prodFlavorCondition +
          (prodTargetedMessages.length > 0 ? ' && ' : '') +
          this.getGroupConditions(message, prodUniques) +
          ') || (' +
          otherFlavorCondition +
          (expTargetedMessages.length > 0 ? ' && ' : '') +
          this.getGroupConditions(message, uniqueGroups) +
          ')'
        )
      case 'experimental':
        return this.getGroupConditions(message, uniqueGroups)
      default:
        return ''
    }
  }

  public async fetchFormulaInsights(projectId?: string) {
    let insights

    if (projectId) {
      insights = await getDocs(query(collection(getFirestore(), `/insights`), where('project', '==', projectId)))
    } else {
      insights = await getDocs(query(collection(getFirestore(), `/insights`)))
    }

    return sortBy(
      insights.docs.map((doc) => ({
        id: doc.id,
        tags: doc.data().tags,
        rule: doc.data().rule,
        rules: doc.data().rules,
        project: doc.data().project,
        spotlight: doc.data().spotlight,
        updatedAt: doc.data().updatedAt,
      })),
      [(o) => o.updatedAt || ''],
    ).reverse()
  }

  public async subscribeToEvals() {
    if (!unsubscribeEvals) {
      this.loading = true

      unsubscribeEvals = onSnapshot(query(collection(getFirestore(), '/evals')), (snap) => {
        if (snap?.docs) {
          const rules: any[] = snap.docs.map((doc) => ({
            ...createDefaultFormula(),
            id: doc.id,
            ...doc.data(),
          }))

          this.allEvals = sortBy(rules, [(o) => o.updatedAt || '']).reverse()
        }
      })

      this.loading = false
    }
  }
  public async unsubscribeFromEvals() {
    if (unsubscribeEvals) {
      unsubscribeEvals()

      unsubscribeEvals = undefined
    }
  }

  public async subscribeToModes() {
    if (!unsubscribeModes) {
      this.loading = true

      unsubscribeModes = onSnapshot(query(collection(getFirestore(), '/mappings-spotlight-modes')), (snap) => {
        if (snap?.docs) {
          const modes: any[] = snap.docs.map((doc) => ({
            ...createDefaultMode(),
            id: doc.id,
            ...doc.data(),
          }))

          this.allModes = sortBy(modes, [(o) => o.order || ''])
        }
      })

      this.loading = false
    }
  }
  public async unsubscribeFromModes() {
    if (unsubscribeModes) {
      unsubscribeModes()

      unsubscribeModes = undefined
    }
  }

  public async subscribeToRules() {
    if (!unsubscribeRules) {
      this.loading = true

      unsubscribeRules = onSnapshot(query(collection(getFirestore(), '/rules')), (snap) => {
        if (snap?.docs) {
          const rules: any[] = snap.docs.map((doc) => ({
            ...createDefaultFormula(),
            id: doc.id,
            ...doc.data(),
          }))

          this.allRules = sortBy(rules, [(o) => o.updatedAt || '']).reverse()
        }
      })

      this.loading = false
    }
  }
  public async unsubscribeFromRules() {
    if (unsubscribeRules) {
      unsubscribeRules()

      unsubscribeRules = undefined
    }
  }

  public async subscribeToGroups() {
    if (!unsubscribeGroups) {
      this.loading = true

      unsubscribeGroups = onSnapshot(query(collection(getFirestore(), '/formulas-message-configs')), (snap) => {
        if (snap?.docs) {
          const groups: any[] = snap.docs.map((doc) => ({
            ...createDefaultFormula(),
            id: doc.id,
            ...doc.data(),
          }))
          this.allGroups = groups
        }
      })

      unsubscribePublishedGroups = onSnapshot(
        query(collection(getFirestore(), '/rollouts-messaging-grouppings')),
        (snap) => {
          if (snap?.docs) {
            const groups: any[] = snap.docs.map((doc) => ({
              ...createDefaultFormula(),
              id: doc.id,
              ...doc.data(),
            }))
            this.publishedGroups = groups
          }
        },
      )

      this.loading = false
    }
  }

  public async unsubscribeFromGroups() {
    if (unsubscribeGroups) {
      unsubscribeGroups()

      unsubscribeGroups = undefined
    }
    if (unsubscribePublishedGroups) {
      unsubscribePublishedGroups()

      unsubscribePublishedGroups = undefined
    }
  }
}
