<template>
  <div style="max-width: 100%">
    <div v-if="!isLoading" class="d-flex flex-column fill-height">
      <div class="message-type">
        <template v-if="insight.category == 'snippet' || insight.category == 'slideshow'">
          <v-tooltip top max-width="350px">
            <template #activator="{ props }">
              <div class="slider" v-bind="props">
                <span v-for="message in messages" :key="message.id + '-slide'" class="slide" />
              </div>
            </template>
            <span>Slides are shown in this order.</span>
          </v-tooltip>
        </template>
      </div>

      <div
        class="flex-grow-1 flex-shrink-1 pb-4 pr-4 mr-n4"
        style="max-height: 480px; overflow: auto"
        :class="`messages${insight.category == 'snippet' || insight.category == 'slideshow' ? ' snippet' : ''}`"
      >
        <MessageItem
          v-for="message in messages"
          :key="message.id"
          :insight="insight"
          :message="message"
          :snapshot-u-r-l="snapshotURLForMessageId(message.id)"
          @duplicate-message="duplicateMessage(message)"
          @edit-group="editGroup(message.id)"
        />
      </div>

      <MessageActions
        :message-name="messageName"
        :notes-count="notesCount"
        :disable-edit="disableEdit"
        @create="create(type)"
        @open-notes-dialog="$refs.notesDialog?.open({ insight, messages, collectionPath: messagePath })"
        @open-reorder-message-dialog="$refs.reorderMessagesDialog?.open(insight, messages, messagePath)"
      />

      <EditRuleDialog
        ref="editRuleDialog"
        :insight="insight"
        :messages="messages"
        :message-path="messagePath"
        :message-snapshots="messageSnapshots"
      />
      <ReorderMessagesDialog ref="reorderMessagesDialog" />
      <NotesDialog ref="notesDialog" />
      <CommitDialog ref="CommitDialog" />
    </div>
  </div>
</template>

<script lang="ts">
  import { orderBy as lodashOrderBy } from 'lodash-es'

  import { VueDraggableNext } from 'vue-draggable-next'

  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Unsubscribe, collection, getFirestore, onSnapshot, orderBy, query } from '@firebase/firestore'

  import { createDefaultMessage, duplicateExistingMessage, getOrigin } from '#views/messages/utilities'

  import { Dialog, Insight, Message } from '#types'

  @Component({
    components: {
      draggable: VueDraggableNext,
    },
  })
  class MessageList extends Vue {
    @Prop() public type!: string
    @Prop() public insight!: Insight
    @Prop() public insightsPath!: string
    @Prop() public messagePath!: string
    @Prop() public messageSnapshots!: any[]
    @Prop() public defaultMessagePath!: string

    @Prop({ default: false }) public disableEdit!: boolean

    declare public $refs: {
      notesDialog: Dialog
      CommitDialog: Dialog
      reorderMessagesDialog: Dialog
      editRuleDialog: Dialog
    }

    @Emit('create')
    public create(param: any) {
      return param
    }

    public notesCount = 0

    public isLoading = true

    public messages: Message[] = []

    private unsubscribe: Unsubscribe | undefined = undefined
    private unsubscribeMessages: Unsubscribe | undefined = undefined

    public get messageName() {
      return this.messagePath.includes('slideshow') ? 'slide' : 'message'
    }

    public created() {
      this.unsubscribe = onSnapshot(query(collection(getFirestore(), this.messagePath)), (snap) => {
        this.notesCount = 0
        snap.docs.forEach((message) => {
          this.notesCount += message.data().notes ? message.data().notes : 0
        })
      })

      this.getMessages()
    }

    public beforeUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe()
        this.unsubscribe = undefined
      }

      if (this.unsubscribeMessages) {
        this.unsubscribeMessages()
        this.unsubscribeMessages = undefined
      }
    }

    public getPageNumber({ page, i }: any) {
      if (i + 1 != page.page) {
        return `${i + 1} (previously ${page.page})`
      }

      return page.page
    }

    public snapshotURLForMessageId(id: string) {
      const snapshot = this.messageSnapshots.find((s) => s.id === id)
      return snapshot ? snapshot.url : undefined
    }

    public async duplicateMessage(message: Message) {
      duplicateExistingMessage(this.insightsPath, message, this.insight.id, this.messages.length)
    }

    public async editGroup(messageId: string) {
      this.$refs.editRuleDialog?.open('targetGroups', {
        selectedMessageId: messageId,
      })
    }

    @Emit('setMessages')
    private setMessages() {
      return this.messages
    }

    private getMessages() {
      this.isLoading = true

      this.unsubscribeMessages = onSnapshot(
        query(collection(getFirestore(), this.messagePath), orderBy('order')),
        (snap) => {
          this.isLoading = false
          this.messages = lodashOrderBy(
            snap.docs
              .map((doc) => ({
                ...createDefaultMessage('', getOrigin(this.defaultMessagePath)),
                id: doc.id,
                ...doc.data(),
              }))
              .filter((doc) => (this.type && this.type == doc.type) || !this.type),
            ['order'],
          )

          this.setMessages()
        },
      )
    }
  }

  export default toNative(MessageList)
</script>

<style lang="scss" scoped>
  .message-type {
    margin: 0 -24px;

    .slider {
      display: inline-block;
    }

    .slide {
      width: 20px;
      height: 5px;
      background: #999;
      display: inline-block;
      margin: 0 3px 10px;

      &:first-of-type {
        background: #000;
      }
    }
  }

  .sections {
    &.list {
      display: grid;
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      grid-template-rows: minmax(min-content, max-content);
      grid-template-columns: repeat(3, minmax(0, 1fr));
      text-align: center;
      margin: 0 -24px;

      .messages {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }

    .messages {
      grid-template-columns: repeat(5, minmax(0, 1fr));
      margin: 0;
    }

    fieldset {
      padding: 0 1rem;
      border: 1px solid #dddddd;
      border-radius: 3px;
      margin: 0 0 1rem;

      legend {
        padding: 3px 12px;
        text-align: left;
        border: 1px solid #dddddd;
        margin: 0 0 5px;
        border-radius: 3px;
      }
    }

    .title-container:hover {
      color: rgb(var(--v-theme-primary));
      cursor: pointer;
    }
  }

  .messages {
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-rows: minmax(min-content, max-content);
    grid-template-columns: repeat(5, minmax(0, 1fr));
    text-align: center;
    margin: 0 -24px;

    &.snippet {
      overflow: auto;
      grid-auto-columns: 250px;
      grid-auto-flow: column;
      grid-template-columns: unset;
    }
  }

  .v-expansion-panels {
    width: 100%;
    display: block;

    .v-expansion-panel {
      margin: 0 0 5px;
      border-radius: 3px;

      &:before {
        box-shadow: none;
      }
      &:after {
        border: 0 !important;
      }

      .v-expansion-panel-title {
        background: #fff;
        border-radius: 3px;
        padding: 14px 22px;

        &--active {
          border-radius: 3px 3px 0 0;
        }
      }
    }

    .v-expansion-panels {
      & > div > .mb-0 {
        margin: 5px 0 !important;
      }
    }

    .v-expansion-panel-header__icon {
      display: none;
    }
  }

  :deep(.theme--light.v-tabs > .v-tabs-bar),
  :deep(.theme--light.v-window) {
    background: none;
  }

  :deep(.theme--light.v-window) {
    padding: 0 !important;
  }

  :deep(.sortable-ghost) {
    border: 1px solid #efefef;

    & > button {
      border: 2px solid rgb(var(--v-theme-primary));
    }
  }

  .insight-editor {
    .v-expansion-panels {
      .v-expansion-panel {
        .v-expansion-panel-header {
          background-color: #f3f5f5;
        }

        .v-expansion-panel-text {
          padding-top: 16px;
        }
      }
    }
  }

  @media (max-width: 1300px) {
    .messages {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-auto-rows: auto;
    }
  }
  @media (max-width: 800px) {
    .messages {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-auto-rows: auto;
    }
  }
  @media (max-width: 600px) {
    .messages {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-auto-rows: auto;
    }
  }
</style>
