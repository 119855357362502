import { pick } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import {
  SAMLAuthProvider,
  Unsubscribe,
  User,
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from 'firebase/auth'
import { doc, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore'

import * as Sentry from '@sentry/vue'

import {
  ContentsStore,
  FeaturesStore,
  FormulasStore,
  InsightsStore,
  PrefsStore,
  ProjectsStore,
  ReleasesStore,
  RolloutsStore,
  SegmentsStore,
  SettingsStore,
  SlideshowsStore,
  TemplatesStore,
  TipthemesStore,
  WorkspaceStore,
} from '#stores'

import { Author } from '#types'

let logoutTimeout: number | null = null

let authStateUnsubscribe: Unsubscribe | null = null

@Store
export class AppStore extends Pinia {
  public user: User | null | undefined = undefined

  public navDrawer = ''
  public currentRoute = ''
  public previousRoute = ''

  public customClaims: any = null

  public roleOverrides: string[] = []

  public sessionExpirationTime: number | null = null

  public get route() {
    return (this.currentRoute || '/').split('/')
  }

  public get author() {
    return pick(this.user, ['uid', 'email', 'photoUrl', 'displayName']) as Author
  }

  public get groups() {
    return this.customClaims?.groups || []
  }

  /**
   * Get the main path of the route, right after the domain
   *
   * Example: https://waltari.com/{mainPath}/subpath
   */
  public get mainPath() {
    return this.currentRoute.split('/')[1]
  }

  public get inEvals() {
    return (this.currentRoute || '').includes('/evals')
  }

  public get inRules() {
    return (this.currentRoute || '').includes('/rules')
  }

  public get inGroups() {
    return (this.currentRoute || '').includes('/grouppings')
  }

  public get inMessages() {
    return (this.currentRoute || '').includes('/messages')
  }

  public get inApprovals() {
    return (this.currentRoute || '').includes('/approvals')
  }

  public get inTemplates() {
    return (this.currentRoute || '').includes('/templates')
  }

  public get inWorkspace() {
    return (this.currentRoute || '').includes('/workspace')
  }

  public get inMessaging() {
    return (this.currentRoute || '').includes('/messaging')
  }

  public get inTipthemes() {
    return (this.currentRoute || '').includes('/tipthemes')
  }

  public get inSlideshows() {
    return (this.currentRoute || '').includes('/slideshows')
  }

  public get canUserEdit() {
    const currentPath = this.currentRoute || ''

    return currentPath.includes('workspace') && currentPath.includes(this.user?.uid || '')
  }

  public get isOnCallUser() {
    return (this.customClaims?.groups || '').includes('Cloud On-Call')
  }

  public get isWaltariAdmin() {
    return (this.customClaims?.groups || '').includes('Waltari admins')
  }

  public get isContentsEditor() {
    return this.roleOverrides.includes('cmsContentsEditor')
      ? false
      : this.isWaltariAdmin ||
          this.customClaims?.aud === 'content-editor-development' ||
          (this.customClaims?.groups || '').includes('CMS Content editors')
  }

  public get isInsightsEditor() {
    return this.roleOverrides.includes('cmsInsightsEditor')
      ? false
      : this.isWaltariAdmin ||
          this.customClaims?.aud === 'content-editor-development' ||
          (this.customClaims?.groups || '').includes('CMS Insight editors')
  }

  public get isAppFeatureAdmin() {
    return this.roleOverrides.includes('appFeatureAdmin')
      ? false
      : this.isOnCallUser ||
          this.isWaltariAdmin ||
          this.customClaims?.aud === 'content-editor-development' ||
          (this.customClaims?.groups || '').includes('APP Feature admins')
  }

  public get isAppReleaseAdmin() {
    return this.roleOverrides.includes('appReleaseAdmin')
      ? false
      : this.isWaltariAdmin ||
          this.customClaims?.aud === 'content-editor-development' ||
          (this.customClaims?.groups || '').includes('APP Release admins')
  }

  public get isOTAConfigAdmin() {
    return this.roleOverrides.includes('otaConfigAdmin')
      ? false
      : this.isWaltariAdmin ||
          this.customClaims?.aud === 'content-editor-development' ||
          (this.customClaims?.groups || '').includes('OTA Config admins')
  }

  public get isOTAContentAdmin() {
    return this.roleOverrides.includes('otaContentAdmin')
      ? false
      : this.isWaltariAdmin ||
          this.customClaims?.aud === 'content-editor-development' ||
          (this.customClaims?.groups || '').includes('OTA Content admins')
  }

  public auth() {
    return new Promise((resolve) => {
      if (authStateUnsubscribe) {
        authStateUnsubscribe()
      }

      authStateUnsubscribe = onAuthStateChanged(getAuth(), async (user) => {
        if (user) {
          if (this.user?.uid !== user.uid) {
            this.setCurrentUser(user)
          }
        } else {
          // Ensure user is logged out if auth state changes

          await this.logout(window.location.pathname !== '/')

          // Auto login if in none root route path

          if (window.location.pathname !== '/') {
            await this.login()
          }
        }

        resolve(user)
      })
    })
  }

  public async login() {
    // This initiates the firebase login flow, initiated by user or the system

    const env = import.meta.env.VITE_APP_ENV

    const provider = new SAMLAuthProvider('saml.oura')

    if (env === 'release') {
      signInWithRedirect(getAuth(), provider)
    } else {
      try {
        await signInWithPopup(getAuth(), provider)

        window.location.reload()
      } catch (_error) {
        console.info('Popup closed or blocked, login interrupted')
      }
    }
  }

  public async logout(reLogin?: boolean) {
    // This initiates the firebase logout and resets state

    new SettingsStore().unsubscribeFromSettings()

    new ProjectsStore().unsubscribeFromProjects()

    new PrefsStore().unsubscribeFromUserPrefs()

    new FormulasStore().unsubscribeFromEvals()
    new FormulasStore().unsubscribeFromModes()
    new FormulasStore().unsubscribeFromRules()

    new InsightsStore().unsubscribeFromInsights()
    new TipthemesStore().unsubscribeFromTipthemes()
    new SlideshowsStore().unsubscribeFromSlideshows()

    new ContentsStore().unsubscribeFromContents()

    new FeaturesStore().unsubscribeFromFeatures()
    new SegmentsStore().unsubscribeFromSegments()

    new ReleasesStore().unsubscribeFromReleases()
    new RolloutsStore().unsubscribeFromRollouts()

    new TemplatesStore().unsubscribeFromTemplates()

    /*new ApprovalsStore().unsubscribeFromReviews()
    new ApprovalsStore().unsubscribeFromNotifications() */

    new WorkspaceStore().unsubscribeFromWorkspace()

    this.user = reLogin ? undefined : null

    if (!reLogin) {
      this.resetAppUrlRouteAndParams()
    }

    await signOut(getAuth())
  }

  public openNavDrawer(drawerName: string) {
    this.navDrawer = drawerName
  }

  public closeNavDrawer(drawerName?: string) {
    if (!drawerName || drawerName === this.navDrawer) {
      this.navDrawer = ''
    }
  }

  public toggleNavDrawer(drawerName: string) {
    if (this.navDrawer === drawerName) {
      this.closeNavDrawer()
    } else {
      this.openNavDrawer(drawerName)
    }
  }

  public toggleRoleOverride(overrideRole: string) {
    if (this.roleOverrides.includes(overrideRole)) {
      sessionStorage.OuraOverrides = this.roleOverrides.filter((o) => o !== overrideRole).join(' ')
    } else {
      sessionStorage.OuraOverrides = [...this.roleOverrides, overrideRole].join(' ')
    }

    window.location.reload()
  }

  /**
   * Commit changes to Firestore, append author and timestamp.
   * Returns data with appended fields.
   *
   * @param comment Comment for the change, appended to author.
   * @param data Original data.
   * @param path Path to Firestore document.
   * @param screenshot (OPTIONAL)
   * @returns Data with appended fields.
   */
  public async commitChanges(comment: string, data: any, path: string, screenshot?: string) {
    const author = {
      ...this.author,
      comment,
    }

    const docData = {
      ...data,
      author,
      updatedAt: serverTimestamp(),
      ...(!data.createdAt && { createdAt: serverTimestamp() }),
      ...(screenshot && { screenshot }),
    }

    await setDoc(doc(getFirestore(), path), docData)

    return docData
  }

  private async setCurrentUser(firebaseUser: User) {
    this.user = firebaseUser

    if (firebaseUser) {
      if (sessionStorage.OuraOverrides) {
        this.roleOverrides = sessionStorage.OuraOverrides.split(' ')
      }

      await firebaseUser?.getIdTokenResult(true).then((result: any) => {
        this.customClaims = result?.claims || {}

        console.info('User:', JSON.parse(JSON.stringify(firebaseUser)))
        console.info('Claims:', JSON.parse(JSON.stringify(this.customClaims)))
        console.info('Last login at:', firebaseUser.metadata.lastSignInTime)

        if (this.customClaims?.auth_time) {
          this.setSessionExpirationTime(+this.customClaims.auth_time * 1000)
        }

        // Store user details to Firestore.
        setDoc(
          doc(getFirestore(), 'users', firebaseUser.uid),
          {
            email: firebaseUser.email,
            photoURL: firebaseUser.photoURL,
            displayName: firebaseUser.displayName,
            groups: this.customClaims?.groups || [],
            lastLogin: firebaseUser.metadata.lastSignInTime || '',
          },
          { merge: true },
        )

        Sentry.setUser({ email: firebaseUser.email || '' })
      })
    }
  }

  private setSessionExpirationTime(expirationTime: number) {
    // Expire session in 12 hours

    if (logoutTimeout) {
      window.clearTimeout(logoutTimeout)
    }

    const sessionDuration = 12 * 60 * 60 * 1000

    const millisecondsUntilExpiration = sessionDuration - (Date.now() - expirationTime)

    if (millisecondsUntilExpiration <= 0) {
      this.sessionExpirationTime = null

      this.logout()
    } else {
      this.sessionExpirationTime = new Date(expirationTime + sessionDuration).getTime()

      logoutTimeout = window.setTimeout(() => this.logout(), millisecondsUntilExpiration)
    }
  }

  private resetAppUrlRouteAndParams(forcePathReset?: boolean) {
    sessionStorage.removeItem('OuraRouteParams')

    if (forcePathReset || window.location.pathname !== '/' || window.location.search.length) {
      window.location.href = '/'
    }
  }
}
