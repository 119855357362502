<template>
  <div>
    <v-toolbar>
      <v-toolbar-items style="display: block; width: 100%">
        <v-tabs v-model="selectedTab">
          <v-tab v-if="noFacets && !inTemplates" value="type" min-width="200" @click="changeActiveTab('type')">
            Type
          </v-tab>

          <v-tab
            v-if="inTemplates"
            min-width="200"
            value="templateSettings"
            @click="changeActiveTab('templateSettings')"
          >
            Settings
          </v-tab>
          <v-tab
            v-for="facet in facets.filter((f) => isVisible(f.key))"
            :key="facet.key"
            :value="`${facet.key}`"
            min-width="200"
            @click="changeActiveTab(facet.key)"
          >
            {{ facet.name }}
          </v-tab>
          <v-tab v-if="isVisible('media')" value="media" min-width="200" @click="changeActiveTab('media')">Media</v-tab>
          <v-tab v-if="isVisible('links')" value="links" min-width="200" @click="changeActiveTab('links')">Links</v-tab>

          <v-menu left offset-y nudge-right="20" nudge-top="-15">
            <template #activator="{ props }">
              <v-btn class="mx-4" icon="mdi-plus-thick" variant="plain" v-bind="props" />
            </template>
            <v-list>
              <v-list-item
                v-if="showAddHome"
                title="Add Home Card"
                prepend-icon="mdi-card-text-outline"
                @click="createFacet('home')"
              />
              <v-list-item
                v-if="showAddView"
                title="Add View / Page"
                prepend-icon="mdi-page-layout-body"
                @click="createFacet('view')"
              />
              <v-list-item
                v-if="showAddNotification"
                title="Add Notification"
                prepend-icon="mdi-bell-outline"
                @click="createFacet('notification')"
              />
              <v-list-item
                v-if="!inWorkspace"
                title="Add Media Files"
                prepend-icon="mdi-folder-multiple-image"
                @click="openMediaItemDialog()"
              />
              <v-list-item
                v-if="!inWorkspace"
                title="Add Links Section"
                prepend-icon="mdi-link-variant"
                @click="createLinks()"
              />
            </v-list>
          </v-menu>

          <v-spacer />

          <v-tab v-if="inTemplates" value="templateStats" min-width="100" @click="changeActiveTab('stats')">
            Stats
          </v-tab>
        </v-tabs>
      </v-toolbar-items>
    </v-toolbar>

    <v-alert v-if="message && message.info" :text="message.info" variant="text" type="info" style="width: 100%" />

    <v-card-text class="pt-0 px-8">
      <v-window v-model="selectedTab">
        <v-window-item value="type" class="mt-6">
          <div>
            <div class="text-primary text-h5 my-6">Settings</div>

            <div class="my-8">
              Messages without facets are native messages which means that the application provides and renders the
              content.
              <br />
              <br />
              Please select the native message type or add a facet from the plus menu to define content for this
              message.
            </div>

            <v-select v-model="message.type" :items="nativeMessages" label="Native page to show" />

            <div class="text-primary text-h5 my-6">Condition</div>

            <div class="d-flex flex-row align-center">
              <Statement v-model="message.condition" class="mb-n4" label="Condition preview" simplify readonly />

              <v-btn class="ml-2" icon="mdi-square-edit-outline" @click="$refs.editRuleDialog?.open(message.id)" />
            </div>
          </div>
        </v-window-item>

        <v-window-item v-for="facet in facets" :key="facet.key" :value="facet.key" class="mt-6">
          <div v-if="hasFacet(facet.key)" class="body-2 my-8">{{ facet.info }}</div>

          <component
            :is="facet.component"
            v-if="hasFacet(facet.key)"
            :message="message"
            :insight="insight"
            :template="template"
            :allowed-components="allowedComponents"
            :required-components="requiredComponents"
            @edit-condition="$refs.editRuleDialog?.open(message.id)"
          />
        </v-window-item>

        <v-window-item value="templateSettings" class="mt-6">
          <div v-if="inTemplates">
            <TemplateSettings
              :template="template"
              :allowed-pages="allowedPages"
              :allowed-facets="allowedFacets"
              :allowed-components="allowedComponents"
              :required-components="requiredComponents"
              @components-changed="componentsChanged($event)"
            />
          </div>
        </v-window-item>

        <v-window-item value="media" class="mt-6">
          <p class="body-2">Here you can add media objects that should be included in the message.</p>
          <MediaObject name="component-media" :message="message" @open-media-item-dialog="openMediaItemDialog()" />
        </v-window-item>
        <v-window-item value="links" class="mt-6">
          <p class="body-2">Here you can add link objects that should be included in the message.</p>
          <LinksObject name="component-links" :links="message.links" @create-new-links-item="createNewLinksItem()" />
        </v-window-item>
        <v-window-item value="templateStats" class="mt-6">
          <div v-if="inTemplates">
            <TemplateStatistics :template="template" />
          </div>
        </v-window-item>
      </v-window>
    </v-card-text>

    <EditRuleDialog
      ref="editRuleDialog"
      :insight="insight"
      :messages="messages"
      :message-path="messagePath"
      :message-snapshots="messageSnapshots"
    />
  </div>
</template>

<script lang="ts">
  import { isEmpty, values } from 'lodash-es'

  import { markRaw } from 'vue'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { facets, nativeMessages } from '#views/messages/constants'

  import { createHomeFacet, createNotificationFacet, createViewFacet } from '#views/messages/utilities'

  import { AppStore } from '#stores'

  import { Dialog, Insight, Message, Template } from '#types'

  interface Facet {
    key: string
    info: string
    name: string
    component: any
  }

  @Component({})
  class Facets extends Vue {
    @Prop() public tab!: string
    @Prop() public insight!: Insight
    @Prop() public insightPath!: string
    @Prop() public message!: Message
    @Prop() public messages!: Message[]
    @Prop() public messageSnapshots!: any[]
    @Prop() public template!: Template
    @Prop() public messagePath!: string
    @Prop() public allowedPages!: string[]
    @Prop() public allowedFacets!: string[]

    @Prop({ default: () => [] }) public allowedComponents!: string[]
    @Prop({ default: () => [] }) public requiredComponents!: string[]

    declare public $refs: {
      editRuleDialog: Dialog
    }

    public selectedTab = 'type'

    public facets: Facet[] = markRaw(facets)

    public nativeMessages = nativeMessages

    private appStore = new AppStore()

    public get showAddView() {
      return !this.message?.facets.view
    }

    public get showAddHome() {
      if (this.template && !this.template?.allowedFacets.includes('home')) {
        return false
      } else if (
        this.insight?.group_id == 'bedtime' ||
        this.insight?.category == 'snippet' ||
        this.insight?.category == 'anniversary'
      ) {
        return !this.message?.facets.home
      }

      return false
    }

    public get showAddNotification() {
      if (this.template && !this.template?.allowedFacets.includes('notification')) {
        return false
      }

      return !this.message?.facets.notification
    }

    public get noFacets() {
      return values(this.message.facets).every(isEmpty)
    }
    public get inWorkspace() {
      return this.appStore.inWorkspace
    }

    public get inTemplates() {
      return this.appStore.inTemplates
    }

    @Watch('tab', { immediate: true })
    protected changeselectedTab() {
      this.selectedTab = this.tab
    }

    @Watch('page')
    protected pageChanged(val: any) {
      this.message.page = val
    }

    @Emit('changeActiveTab')
    public changeActiveTab(tab: string) {
      return tab
    }

    @Emit('componentsChanged')
    public componentsChanged(event: any) {
      return event
    }

    @Emit('openMediaItemDialog')
    public openMediaItemDialog() {
      return true
    }

    @Emit('createNewLinksItem')
    public createNewLinksItem() {
      return true
    }

    public mounted() {
      if (this.inTemplates) {
        this.selectedTab = 'templateSettings'
      } else {
        const firstValidFacet = this.facets.find((facet) => this.isVisible(facet.key))

        this.selectedTab = firstValidFacet?.key || 'type'
      }

      this.changeActiveTab(this.selectedTab)
    }

    public hasFacet(key: string): boolean {
      return (this.message.facets as any)[key] != null
    }

    public isVisible(key: string): boolean {
      return (
        (key === 'links' && this.hasLinksObjects()) ||
        (key === 'media' && this.hasMediaObjects()) ||
        this.messageFacets()
          .map((facet) => facet.key)
          .includes(key)
      )
    }

    public createLinks() {
      this.message!.links = []

      this.createNewLinksItem()
    }

    public createFacet(key: string) {
      let data: any = null

      delete this.message.type

      switch (key) {
        case 'home':
          data = createHomeFacet(this.message.id)
          this.selectedTab = 'home'
          break
        case 'view':
          data = createViewFacet()
          this.selectedTab = 'view'
          break
        case 'notification':
          data = createNotificationFacet(this.message.id)
          this.selectedTab = 'notification'
          break
        default:
          console.error(`Missing handling for facet ${key}`)
          return
      }

      this.message.facets[key] = data
    }

    private messageFacets() {
      return facets.filter(
        (facet) =>
          (!this.inTemplates &&
            (!this.allowedFacets || this.allowedFacets?.includes('view')) &&
            facet.key === 'view') ||
          this.hasFacet(facet.key),
      )
    }

    private hasMediaObjects() {
      return this.message.media != null
    }

    private hasLinksObjects() {
      return this.message.links != null
    }
  }

  export default toNative(Facets)
</script>
